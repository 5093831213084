'use client'

import type { RTTextNode } from '@prismicio/types'
import type { ReactNode } from 'react'
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getCookie } from '@patrianna/shared-utils/cookie'
import { getTranslationText } from './utils/getTranslationText'
import { decode } from '@patrianna/core-components/src/context/translations/utils/encryption'

type Translation = Record<string, string | RTTextNode[]>
type Translations = Record<string, Translation>

type TranslationContextValue = {
  t: (key: string) => any
  dynamicValues: Record<string, string | number>
}

const TranslationsContext = createContext<TranslationContextValue>(null as any)
TranslationsContext.displayName = 'TranslationsContext'

interface TranslationsProviderProps {
  children: ReactNode
  translations: Translations
  prismicDynamicValuesConfig: Record<string, string>
  shouldDecodeTranslations?: boolean
}

function TranslationsProvider(props: TranslationsProviderProps) {
  const { children, translations = {}, prismicDynamicValuesConfig = {}, shouldDecodeTranslations = false } = props
  const [isDebugMode, setDebugMode] = useState(false)

  useEffect(() => {
    setDebugMode(!!getCookie('PRISMIC_KEYS', document.cookie))
  }, [])

  const decodedTranslations = useMemo(() => {
    if (shouldDecodeTranslations) {
      return decode(translations as unknown as string)
    }

    return translations
  }, [translations, shouldDecodeTranslations])

  const t = useCallback(
    (key: string, dynamicValues: Record<string, string | number> = {}) =>
      getTranslationText(key, decodedTranslations, isDebugMode, { ...prismicDynamicValuesConfig, ...dynamicValues }),
    [decodedTranslations, isDebugMode, prismicDynamicValuesConfig]
  )

  const translationsValueMemo = useMemo(
    () => ({
      t,
      dynamicValues: prismicDynamicValuesConfig,
    }),
    [t, prismicDynamicValuesConfig]
  )

  return <TranslationsContext.Provider value={translationsValueMemo}>{children}</TranslationsContext.Provider>
}

const TranslationsProviderMemo = memo(TranslationsProvider)

function useTranslation() {
  const context = useContext(TranslationsContext)

  if (context === undefined) {
    throw new Error('useTranslation must be used within a TranslationsProvider')
  }

  return context.t
}

function usePrismicDynamicValues() {
  const context = useContext(TranslationsContext)

  if (context === undefined) {
    throw new Error('usePrismicDynamicValues must be used within a PrismicDynamicValuesContext')
  }

  return context.dynamicValues
}

export { TranslationsProviderMemo as TranslationsProvider, useTranslation, usePrismicDynamicValues }
